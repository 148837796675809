import { env } from "./env";

/**
 * Parses a feature flag key located in the environment variables
 * @returns Whether the feature flag is active
 */
export function flag(key: keyof ImportMetaEnv): boolean {
  return env[key] === "1";
}
/**
 * Feature flags
 */
export const featureFlags = {
  enableBankLogo: flag("VITE_ENABLE_BANK_LOGO"),
  enableCorrespondenteBanqueiro: flag("VITE_ENABLE_CORRESPONDENTE_BANQUEIRO"),
  enableImageDocumentsRequirementsToUpload: flag(
    "VITE_ENABLE_IMAGE_DOCUMENTS_REQUIREMENTS_TO_UPLOAD",
  ),
  /** Habilita seguro prestamista para o produto "Margem Livre" */
  enableSeguroPrestamista: flag("VITE_ENABLE_SEGURO_PRESTAMISTA"),
  /** Habilita seguro prestamista para o produto "Portabilidade + Refinanciamento" */
  enableSeguroPrestamistaPortRefin: flag(
    "VITE_ENABLE_SEGURO_PRESTAMISTA_PORT_REFIN",
  ),
  /** Habilita seguro prestamista para o produto "Refinanciamento" */
  enableSeguroPrestamistaRefin: flag("VITE_ENABLE_SEGURO_PRESTAMISTA_REFIN"),
  /** Habilita finalização automática quando nenhum contrato de inss for selecionado, pulando as etapas de biometria, documentos e termos */
  enableAutoSignEmptyEnvelope: flag("VITE_ENABLE_AUTOSIGN_EMPTY_ENVELOPE"),
  /** Habilita validação de token da Caixa */
  enableTokenValidation: flag("VITE_ENABLE_TOKEN_VALIDATION"),
} as const;
