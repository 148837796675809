import { ImgIfExists } from "@components/ImgIfExists";
import { useBankLogo } from "@contexts/ClientSettings/BankLogo";
import { useClientSettings } from "@hooks/useClientSettings";
import { featureFlags } from "@settings";
import { Divider } from "antd";

export const BankLogo = () => {
  const client = useClientSettings();
  const { url } = useBankLogo();

  const bankLogo = featureFlags.enableBankLogo ? url : client.assets.bankLogo;

  return (
    <>
      {bankLogo && <Divider type="vertical" className="mx-6 h-8" />}
      <ImgIfExists
        alt="Logo do banco"
        className="max-h-full max-w-full"
        src={bankLogo}
      />
    </>
  );
};
