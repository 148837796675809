import { createBrowserRouter } from "react-router-dom";

import { ProvidersAndConfigs } from "@components/ProvidersAndConfigs";

const routeEnvelope = () => import("@pages/Envelope");
const routeReformalizacao = () => import("@pages/Reformalizacao");
const routeRetention = () => import("@pages/Retention");

export const router = createBrowserRouter(
  [
    {
      element: <ProvidersAndConfigs />,
      children: [
        {
          path: "/",
          lazy: () => import("@pages/Home"),
        },
        {
          path: "/:token",
          lazy: () => routeEnvelope(),
        },
        {
          path: "/envelope/:token",
          lazy: () => routeEnvelope(),
        },
        {
          path: "/:token/rogado",
          lazy: () => routeEnvelope(),
        },
        {
          path: "/:token/reformalizacao",
          lazy: () => routeReformalizacao(),
        },
        {
          path: "/:token/reformalizacao/rogado",
          lazy: () => routeReformalizacao(),
        },
        {
          path: "/retencao/:token",
          lazy: () => routeRetention(),
        },
        {
          path: "/in100/:cpf/:beneficio",
          lazy: () => import("@pages/In100"),
        },
        {
          path: "/autorizacao-in100/:cpf",
          lazy: () => import("@pages/In100"),
        },
        {
          path: "/user/:uniqueId",
          lazy: () => import("@pages/User"),
        },
      ],
    },
  ],
  {
    future: {
      v7_normalizeFormMethod: true,
    },
  },
);
