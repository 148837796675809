export const formatToMoney = (value: string | number, fallback = "") => {
  if (!value) return fallback;

  return Number(value).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
};

export const formatToPercent = (
  value?: number | null,
  options: Intl.NumberFormatOptions = {},
) => {
  if (!value) return "";

  const val = value > 1 ? value / 100 : value;

  return val.toLocaleString("pt-br", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  });
};

export const formatCpf = (cpf?: string) => {
  return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") || "";
};

export const formatCpfNoMask = (cpf?: string) => {
  return cpf?.replace(/\D/g, "") || "";
}

export const formatMonthAmount = (months?: number) => {
  if (typeof months === "undefined") return "";
  return `${months} ${months === 1 ? "mês" : "meses"}` as const;
};

export const formatOriginUrl = (url?: string) => {
  if (!url) return "";
  if (url.startsWith("http")) {
    return new URL(url).origin;
  } else {
    return new URL(`${location.protocol}//${url}`).origin;
  }
};

export const formatMaskPhoneNumber = (phoneNumber: string) => {
  const lastFourDigits = phoneNumber.slice(-4);
  return `(**) *****-${lastFourDigits}`;
};
