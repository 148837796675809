import { useClientSettingsContext } from "@hooks/useClientSettings";
import { createContext, ReactNode, useContext, useMemo } from "react";

export const BankLogoContext = createContext({ url: "" });

export const useBankLogo = () => useContext(BankLogoContext);

export type BankLogoProviderProps = { children?: ReactNode; url?: string };

export const BankLogoProvider = ({ children, url }: BankLogoProviderProps) => {
  const { themeType } = useClientSettingsContext();

  const isDefaultTheme = themeType === "default";
  const staticUrl = isDefaultTheme ? "/bank-logo.png" : "/pine-logo.png";

  const contextState = useMemo(
    () => ({ url: url ?? staticUrl }),
    [url, staticUrl],
  );

  return (
    <BankLogoContext.Provider value={contextState}>
      {children}
    </BankLogoContext.Provider>
  );
};
