import { Layout } from "antd";

import { BankLogo } from "@components/BankLogo";
import { Logo } from "@components/Logo";

export const Header = ({ invisible }: { invisible?: boolean }) => {
  return (
    <Layout.Header className={`relative flex items-center bg-white shadow-sm`}>
      <div className={`flex h-9 w-24 items-center`}>
        {!invisible && (
          <>
            <Logo />
            <BankLogo />
          </>
        )}
      </div>
    </Layout.Header>
  );
};
